import * as React from 'react';
import useBondQuery from 'hooks/useBondQuery';
import { gql } from '@apollo/client';
import { CheckPromoCodeResult } from '@bondvet/types/promoCodes';
import {
    LocalizedElement,
    LocalizedElementMap,
    useTranslate,
} from '@bondvet/web-app-i18n/util';
import { TranslateFunction } from '@bondvet/web-app-i18n/util/types';

const query = gql`
    query promoCodes($vetspireAppointmentId: ID) {
        checkPromoCodes(
            vetspireAppointmentId: $vetspireAppointmentId
            ignoreCurrentAppointment: true
        ) {
            _id
            code
            valid
            amount
            patientTagId
            amountType
            errorReason
        }
    }
`;

type AmountType = 'custom' | 'patientTag';

export type PromoCode = CheckPromoCodeResult & {
    _id: string;
    code: string;
    amountType?: AmountType;
    patientTagId?: string | null;
};

interface PromoCodesQuery {
    checkPromoCodes: ReadonlyArray<PromoCode>;
}

interface PromoCodesVariables {
    vetspireAppointmentId?: string;
}

export interface PromoCodeOption {
    key: string;
    amount?: string | null;
    patientTagId?: string | null;
    amountType?: AmountType;
    label: string;
    valid?: boolean;
    errorReason?: string | null;
    disabled?: boolean;
}

export interface UsePromoCodes {
    isLoading: boolean;
    valid: readonly PromoCodeOption[];
    invalid: readonly PromoCodeOption[];
}

function getPromoCodeError(
    error: string | null | undefined,
    translate: TranslateFunction,
): string | LocalizedElement | LocalizedElementMap {
    if (!error) {
        return 'Unknown Error';
    }

    const errorParts = error.split(':');

    if (!errorParts || errorParts.length === 0) {
        return 'Unknown Error';
    }

    const [errorReason] = errorParts;

    switch (errorReason) {
        case 'not first time':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.notFirstTime',
            );
        case 'not first patient visit':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.notFirstTimePatient',
            );
        case 'already used for patient':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.alreadyUsedForPatient',
            );
        case 'already used':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.alreadyUsed',
            );
        case 'conflicting patient tag':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.conflictingPatientTag',
            );
        case 'invalid appointment category':
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.apptTypeNotAllowed',
            );
        case 'promo code not valid for this animal only for partnership':
            if (errorParts.length === 2) {
                return translate(
                    'vetspireExtension.clientDetails.credits.promoCodeErrors.petNotAllowedPartnership',
                    { organization: errorParts[1].trim() },
                );
            }
            return translate(
                'vetspireExtension.clientDetails.credits.promoCodeErrors.petNotAllowed',
            );
        default:
            return errorReason ?? 'Unknown Error';
    }
}

export default function usePromoCodes(
    skip: boolean,
    vetspireAppointmentId?: string,
): UsePromoCodes {
    const translate = useTranslate();
    const { data, loading: isLoading } = useBondQuery<
        PromoCodesQuery,
        PromoCodesVariables
    >(query, {
        fetchPolicy: 'cache-and-network',
        skip,
        variables: {
            vetspireAppointmentId,
        },
    });

    const mappedCodes = React.useMemo(() => {
        if (data?.checkPromoCodes) {
            return data.checkPromoCodes.map((promoCode) => ({
                key: promoCode._id,
                label: promoCode.code,
                amount: (promoCode.amount / 100).toFixed(2),
                patientTagId: promoCode.patientTagId,
                amountType: promoCode.amountType,
                valid: promoCode.valid,
                errorReason: !promoCode.valid
                    ? (getPromoCodeError(
                          promoCode.errorReason,
                          translate,
                      ) as string)
                    : null,
                disabled: !promoCode.valid,
            }));
        }
        return [];
    }, [data?.checkPromoCodes, translate]);

    return {
        isLoading,
        valid: React.useMemo(() => {
            // if feature flag is turned off we dont collect the appointment id
            // which means we can not check if the codes are valid
            return mappedCodes.filter(({ valid }) => !!valid);
        }, [mappedCodes]),
        invalid: React.useMemo(() => {
            return mappedCodes.filter(({ valid }) => !valid);
        }, [mappedCodes]),
    };
}
