import * as React from 'react';
import type { Location } from '@bondvet/types/generated/vetspire';
import useVetspireQuery from 'hooks/useVetspireQuery';
import { getFallbackTimezone } from 'lib/utils';
import type { ExecutableDefinitionNode } from 'graphql/language/ast';
import { gql } from '@apollo/client';
import type { GetExpandedAccessSlotsArguments } from '@bondvet/types/intradayCapacity';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import type { ExpandedAccessSlotWithProvider } from '../types';

export const expandedAccessSlotsQuery = gql`
    query expandedAccessSlotsQuery(
        $vetspireLocationId: ID!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        expandedAccessSlots(
            vetspireLocationId: $vetspireLocationId
            fromDate: $fromDate
            toDate: $toDate
        ) {
            _id
            date
            providerId
            availableSlots
            provider {
                id
                name
            }
        }
    }
`;

// we need the location timezone in order to convert the ISO timeslots
// to the label in the UI.
const locationTimezoneQuery = gql`
    query locationTimezone($locationId: ID!) {
        location(id: $locationId) {
            timezone
        }
    }
`;

type LocationTimezoneQueryResult = {
    location: null | Pick<Location, 'timezone'>;
};

type LocationTimezoneQueryVariables = {
    locationId: string;
};

export const refetchQueries = expandedAccessSlotsQuery.definitions
    .map(
        (definition) =>
            (definition as ExecutableDefinitionNode).name?.value || '',
    )
    .filter((name) => name !== '');

type ExpandedAccessSlotsQueryResult = {
    expandedAccessSlots: readonly ExpandedAccessSlotWithProvider[];
};

// the key is the date and the provider's ID, joined by '|'
export type ExpandedAccessSlots = Record<
    string,
    ExpandedAccessSlotWithProvider
>;

export default function useExpandedAccessSlotsInDateRange(
    vetspireLocationId: string,
    fromDate: string,
    toDate: string,
    skip = false,
): {
    expandedAccessSlots: ExpandedAccessSlots;
    loading: boolean;
    refetch: () => void;
    locationTimezone: string;
} {
    const {
        data,
        loading,
        refetch: refetchSlots,
    } = useSchedulingQuery<
        ExpandedAccessSlotsQueryResult,
        GetExpandedAccessSlotsArguments
    >(expandedAccessSlotsQuery, {
        variables: {
            vetspireLocationId,
            fromDate,
            toDate,
        },
        fetchPolicy: 'no-cache',
        skip,
    });

    const { data: timezoneData } = useVetspireQuery<
        LocationTimezoneQueryResult,
        LocationTimezoneQueryVariables
    >(locationTimezoneQuery, {
        variables: { locationId: vetspireLocationId },
        fetchPolicy: 'cache-and-network',
    });

    const refetch = React.useCallback(() => {
        refetchSlots().then();
    }, [refetchSlots]);

    return {
        loading,
        refetch,
        locationTimezone:
            timezoneData?.location?.timezone ?? getFallbackTimezone(),
        expandedAccessSlots: React.useMemo(() => {
            const slots = data?.expandedAccessSlots ?? [];
            const map: ExpandedAccessSlots = {};

            for (const slot of slots) {
                const { providerId, date } = slot;
                const key = [date, providerId].join('|');

                map[key] = slot;
            }

            return map;
        }, [data?.expandedAccessSlots]),
    };
}
