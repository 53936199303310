import * as React from 'react';
import { Patient } from 'api/clients/queries';
import { vetspireIdToGuid } from 'pages/Suki/utils';
import useCreateSukiAmbientSessionMutation from 'pages/Suki/hooks/useCreateSukiAmbientSessionMutation';
import useUpdateSukiAmbientSessionMutation from 'pages/Suki/hooks/useUpdateSukiAmbientSessionMutation';
import { setSukiMode as setGlobalSukiMode } from 'lib/vetspireActions';
import { SukiAssistant, useSuki } from '@suki-sdk/react';
import { SukiMode } from 'pages/Suki/types';
import { SukiAmbientSessionStatus } from '@bondvet/types/suki';
import { SukiNoteData, SukiEncounter } from '../../types';

export interface AssistantProps {
    vetspireProviderId: string;
    vetspireClientId: string;
    vetspirePatientId: string;
    vetspireEncounterId: string | null;
    noteTypeId: string | null;
    patient: Patient;
    setError: (error: string) => void;
    sukiMode: SukiMode;
    setSukiMode: (mode: SukiMode) => void;
    onNoteSubmit: (ambientId: string, data: SukiNoteData) => Promise<void>;
}

export default function Assistant({
    vetspireProviderId,
    vetspireClientId,
    vetspirePatientId,
    vetspireEncounterId,
    noteTypeId,
    patient,
    sukiMode,
    setSukiMode: setLocalSukiMode,
    onNoteSubmit,
}: AssistantProps) {
    const { on } = useSuki();

    const [runCreateSukiAmbientSession] = useCreateSukiAmbientSessionMutation();

    const [runUpdateSukiAmbientSession] = useUpdateSukiAmbientSessionMutation();

    const sukiEncounter = React.useMemo<SukiEncounter>(() => {
        return {
            identifier: vetspireEncounterId
                ? vetspireIdToGuid(vetspireEncounterId)
                : undefined,
            patient: {
                identifier: vetspireIdToGuid(vetspirePatientId),
                kind: patient?.species,
                name: {
                    use: `${patient?.client?.givenName} ${patient?.client?.familyName ?? ''} | ${patient?.name ?? ''}`,
                    family: `${patient?.client?.familyName ?? '-'} | ${patient?.name ?? ''}`,
                    given: [patient?.client?.givenName ?? '-'],
                    suffix: [],
                },
                gender: patient?.sex ?? '',
                birthDate: patient?.birthDate ?? '2000-01-01',
            },
        };
    }, [
        patient?.birthDate,
        patient?.client?.familyName,
        patient?.client?.givenName,
        patient?.name,
        patient?.sex,
        patient?.species,
        vetspireEncounterId,
        vetspirePatientId,
    ]);

    const createSukiAmbientSession = React.useCallback(
        async (ambientId: string) => {
            return runCreateSukiAmbientSession({
                variables: {
                    _id: ambientId,
                    input: {
                        type: vetspireEncounterId ? 'encounter' : 'phone',
                        vetspireProviderId,
                        vetspireClientId,
                        vetspirePatientId,
                        vetspireEncounterId,
                        status: 'started',
                    },
                },
            });
        },
        [
            runCreateSukiAmbientSession,
            vetspireEncounterId,
            vetspireProviderId,
            vetspireClientId,
            vetspirePatientId,
        ],
    );

    const sessionInProgress = React.useRef(false);
    const activeAmbientId = React.useRef<string | null>(null);

    const updateSukiAmbientSession = React.useCallback(
        async (ambientId: string, newStatus: SukiAmbientSessionStatus) => {
            await runUpdateSukiAmbientSession({
                variables: {
                    _id: ambientId,
                    input: {
                        status: newStatus,
                    },
                },
            });
        },
        [runUpdateSukiAmbientSession],
    );

    const onAmbientUpdate = React.useCallback(
        async (data: {
            ambientId: string;
            isAmbientInProgress: boolean;
            isAmbientPaused: boolean;
        }) => {
            console.info('Suki ambient:update: ', data);

            let newSukiMode: SukiMode = 'idle';
            if (data.isAmbientInProgress) {
                newSukiMode = data.isAmbientPaused ? 'paused' : 'inProgress';
            }

            if (newSukiMode !== sukiMode) {
                setLocalSukiMode(newSukiMode);
                setGlobalSukiMode(newSukiMode);
            }

            if (!sessionInProgress.current) {
                if (data.isAmbientInProgress) {
                    sessionInProgress.current = true;
                    await createSukiAmbientSession(data.ambientId);

                    activeAmbientId.current = data.ambientId;
                } else {
                    sessionInProgress.current = false;
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (!data.isAmbientInProgress) {
                    sessionInProgress.current = false;

                    if (activeAmbientId.current) {
                        await updateSukiAmbientSession(
                            data.ambientId,
                            'finished',
                        );
                    }
                }
            }
        },
        [
            createSukiAmbientSession,
            setLocalSukiMode,
            sukiMode,
            updateSukiAmbientSession,
        ],
    );

    React.useEffect(() => {
        const unregisterUpdate = on('ambient:update', onAmbientUpdate);

        return () => {
            unregisterUpdate();
        };
    }, [on, onAmbientUpdate]);

    const localOnNoteSubmit = React.useCallback(
        async (data: SukiNoteData) => {
            await onNoteSubmit(data.noteId, data);
            await updateSukiAmbientSession(data.noteId, 'processed');
        },
        [onNoteSubmit, updateSukiAmbientSession],
    );

    return (
        <SukiAssistant
            encounter={sukiEncounter}
            ambientOptions={
                noteTypeId
                    ? {
                          prefill: { noteTypeId },
                          enableNoteTypeSelection: false,
                      }
                    : undefined
            }
            uiOptions={{
                showCloseButton: false,
                showCreateEmptyNoteButton: false,
            }}
            onNoteSubmit={localOnNoteSubmit}
        />
    );
}
