import * as React from 'react';
import { gql } from '@apollo/client';
import { Appointment } from '@bondvet/types/appointments';
import useVetspireQuery from 'hooks/useVetspireQuery';
import { startOfDay } from 'date-fns';

type UpcomingAppointment = Pick<
    Appointment,
    | '_id'
    | 'animalId'
    | 'pet'
    | 'species'
    | 'locationId'
    | 'appointmentTypeId'
    | 'appointmentTypeName'
> & { locationName: string; date: string };

type UpcomingVetspireAppoinmtent = {
    id: string;
    start: string;
    type?: {
        id: string;
        name: string;
    };
    location?: {
        id: string;
        name: string;
    };
    patient?: {
        id: string;
        name: string;
        species: string;
    };
};

export type UpcomingVisitOption = {
    id: string;
    appointmentData: Omit<UpcomingAppointment, '_id'>;
};

const query = gql`
    query upcomingAppointments($clientId: ID!, $start: DateTime!) {
        appointments(clientId: $clientId, start: $start) {
            id
            start
            type {
                id
                name
            }
            location {
                id
                name
            }
            patient {
                id
                name
                species
            }
        }
    }
`;

interface UpcomingAppointmentsQuery {
    appointments: ReadonlyArray<UpcomingVetspireAppoinmtent>;
}

interface UpcomingAppointmentsVariables {
    clientId: string;
    start: string;
}

export interface UseUpcomingVisits {
    isLoading: boolean;
    options: readonly UpcomingVisitOption[];
}

type UpcomingVisitsProps = {
    clientId: string;
    skip: boolean;
};

export default function useUpcomingVisits({
    clientId,
    skip,
}: UpcomingVisitsProps): UseUpcomingVisits {
    const startDate = React.useMemo(
        () => startOfDay(new Date()).toISOString(),
        [],
    );
    const { data, loading } = useVetspireQuery<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsVariables
    >(query, {
        fetchPolicy: 'cache-and-network',
        variables: { clientId, start: startDate },
        skip,
    });

    const options = React.useMemo(() => {
        if (data?.appointments) {
            return data.appointments.map(
                ({
                    id,
                    start,
                    type,
                    location,
                    patient,
                }: UpcomingVetspireAppoinmtent) => ({
                    id,
                    appointmentData: {
                        animalId: patient?.id,
                        pet: patient?.name,
                        species: patient?.species,
                        appointmentTypeId: type?.id,
                        appointmentTypeName: type?.name,
                        date: start,
                        locationName: location?.name,
                        locationId: location?.id,
                    },
                }),
            ) as UpcomingVisitOption[];
        }
        return [];
    }, [data?.appointments]);

    return {
        isLoading: loading,
        options,
    };
}
