import * as React from 'react';
import {
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_TWEAKS,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';
import { gql } from '@apollo/client';
import { VetspireSetting } from '@bondvet/types/vetspire';
import useBondQuery from './useBondQuery';

type ProjectedVetspireSettings = Pick<
    VetspireSetting,
    | 'renameDepartmentField'
    | 'makeDepartmentFieldRequired'
    | 'renamePostcardMarketingField'
    | 'disableNonDoctorTextMessaging'
    | 'warnOnOptedOutTexting'
    | 'showExpandedAccessSlots'
    | 'interceptCancelReschedule'
    | 'interceptClientUpdates'
    | 'interceptCreateOrder'
    | 'sukiEncounterTypeId'
    | 'noChargeFeeSchedules'
    | 'noChargeFeeDepartments'
    | 'travelCertificateURLTemplate'
    | 'travelCertificates'
>;

const defaultVetspireSettings: ProjectedVetspireSettings = {
    renameDepartmentField: false,
    makeDepartmentFieldRequired: false,
    renamePostcardMarketingField: false,
    disableNonDoctorTextMessaging: false,
    warnOnOptedOutTexting: false,
    showExpandedAccessSlots: false,
    interceptCancelReschedule: false,
    interceptClientUpdates: false,
    interceptCreateOrder: false,
    sukiEncounterTypeId: null,
    noChargeFeeSchedules: ['procedures'],
    noChargeFeeDepartments: ['procedure'],
    travelCertificateURLTemplate: '',
    travelCertificates: [],
};

const vetspireSettingsQuery = gql`
    query globalVetspireSettings {
        globalVetspireSettings {
            renameDepartmentField
            makeDepartmentFieldRequired
            renamePostcardMarketingField
            disableNonDoctorTextMessaging
            warnOnOptedOutTexting
            showExpandedAccessSlots
            interceptCancelReschedule
            interceptClientUpdates
            interceptCreateOrder
            sukiEncounterTypeId
            noChargeFeeSchedules
            noChargeFeeDepartments
            travelCertificateURLTemplate
            travelCertificates {
                name
                cert
            }
        }
    }
`;

interface VetspireSettingsQueryResult {
    globalVetspireSettings: ProjectedVetspireSettings;
}

export default function useVetspireSettings(): ProjectedVetspireSettings {
    const [vetspireSettings, setVetspireSettings] =
        React.useState<ProjectedVetspireSettings>(defaultVetspireSettings);

    useBondQuery<VetspireSettingsQueryResult>(vetspireSettingsQuery, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (result) => {
            const newVetspireSettings: ProjectedVetspireSettings = {
                renameDepartmentField:
                    result.globalVetspireSettings.renameDepartmentField ??
                    defaultVetspireSettings.renameDepartmentField,
                makeDepartmentFieldRequired:
                    result.globalVetspireSettings.makeDepartmentFieldRequired ??
                    defaultVetspireSettings.makeDepartmentFieldRequired,
                renamePostcardMarketingField:
                    result.globalVetspireSettings
                        .renamePostcardMarketingField ??
                    defaultVetspireSettings.renamePostcardMarketingField,
                disableNonDoctorTextMessaging:
                    result.globalVetspireSettings
                        .disableNonDoctorTextMessaging ??
                    defaultVetspireSettings.disableNonDoctorTextMessaging,
                warnOnOptedOutTexting:
                    result.globalVetspireSettings.warnOnOptedOutTexting ??
                    defaultVetspireSettings.warnOnOptedOutTexting,
                showExpandedAccessSlots:
                    result.globalVetspireSettings.showExpandedAccessSlots ??
                    defaultVetspireSettings.showExpandedAccessSlots,
                interceptCancelReschedule:
                    result.globalVetspireSettings.interceptCancelReschedule ??
                    defaultVetspireSettings.interceptCancelReschedule,
                interceptClientUpdates:
                    result.globalVetspireSettings.interceptClientUpdates ??
                    defaultVetspireSettings.interceptClientUpdates,
                interceptCreateOrder:
                    result.globalVetspireSettings.interceptCreateOrder ??
                    defaultVetspireSettings.interceptCreateOrder,
                sukiEncounterTypeId:
                    result.globalVetspireSettings.sukiEncounterTypeId ??
                    defaultVetspireSettings.sukiEncounterTypeId,
                noChargeFeeSchedules:
                    result.globalVetspireSettings.noChargeFeeSchedules ??
                    defaultVetspireSettings.noChargeFeeSchedules,
                noChargeFeeDepartments:
                    result.globalVetspireSettings.noChargeFeeDepartments ??
                    defaultVetspireSettings.noChargeFeeDepartments,
                travelCertificateURLTemplate:
                    result.globalVetspireSettings
                        .travelCertificateURLTemplate ??
                    defaultVetspireSettings.travelCertificateURLTemplate,
                travelCertificates:
                    result.globalVetspireSettings.travelCertificates ??
                    defaultVetspireSettings.travelCertificates,
            };

            setVetspireSettings(newVetspireSettings);

            sendBackgroundMessage(
                MESSAGE_SOURCE_NAVIGATION,
                [MESSAGE_TARGET_TWEAKS],
                'setVetspireSettings',
                { vetspireSettings: newVetspireSettings },
            );
        },
    });

    return vetspireSettings;
}
